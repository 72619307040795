// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import LoginCom from '@/components/LoginCom.vue';
import DashboardCom from '@/components/DashboardCom.vue';
import GameCategories from '@/components/GameCategories.vue';
import GamesCom from '@/components/GamesCom.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginCom,
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardCom,
    meta: { requiresAuth: true }
  },
  {
    path: '/gamecategories',
    name: 'Gamecategory',
    component: GameCategories,
    meta: { requiresAuth: true }
  },
  {
    path: '/games',
    name: 'GamesCom',
    component: GamesCom,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/components/UsersCom.vue'),
    meta: { requiresAuth: true }
  }
  // add other routes here
];

const router = createRouter({
  // Specify the router mode and base URL
  history: createWebHashHistory(''),  // This is correct for serving your app under /admin/
  routes // short for `routes: routes`
});

// Navigation Guard to check for authentication
router.beforeEach((to, from, next) => {
  const userToken = localStorage.getItem('userToken');
  if (!userToken && to.path !== '/') {
    // Redirect to login page if no user token and not already heading to login
    next('/');
  } else {
    next();
  }
});

export default router;
