import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import PrimeVue from 'primevue/config'; // Import PrimeVue config

import 'primevue/resources/themes/saga-blue/theme.css'; // Theme CSS
import 'primevue/resources/primevue.min.css'; // PrimeVue CSS
import 'primeicons/primeicons.css'; // PrimeIcons CSS


const app = createApp(App)
app.use(PrimeVue);
app.use(router);
app.use(Toast, {
  // Optional configuration options
});

app.mount('#app');

// Clear sessionStorage when the page is about to be unloaded
/* window.addEventListener('beforeunload', () => {
  sessionStorage.removeItem('userToken');  // Adjust this to your token/session storage key
  // Optionally, you could log the user out from the server side as well
}); */