<template>
    <footer class="main-footer">
      <strong>Copyright &copy; 2024 - PUBG MOBILE</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.0.5
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterCom'
  }
  </script>
  