<template>
  <div class="wrapper">
    <!-- Navbar and Sidebar only rendered if not on Login page -->
    <div class="preloader flex-column justify-content-center align-items-center">
      <img class="animation__shake" src="dist/img/VAyR.gif" alt="AdminLTELogo" height="60" width="60">
      </div>
    <Navbar v-if="showLayout" />
    <Sidebar v-if="showLayout" />

    <!-- Content Wrapper. Contains page content -->
    <!-- Dynamically add 'content-wrapper-auth' class when showLayout is true -->
    <div :class="{ 'content-wrapper': showLayout }">
            <!-- Router View -->
      <router-view></router-view>
    </div>
    <!-- /.content-wrapper -->

    <!-- Footer -->
    <Footer  v-if="showLayout"/>
  </div>
</template>

<script>

import Navbar from './components/NavbarCom.vue'
import Sidebar from './components/SidebarCom.vue'
import Footer from './components/FooterCom.vue'

export default {
  name: 'App',
  components: {
    Navbar,
   Sidebar,
    Footer
  },
  computed: {
    // Compute whether or not to show the layout based on the route
    showLayout() {
      // Check if the current route requires the auth layout
      return this.$route.meta.requiresAuth === true;
    }
  }
}
</script>

<style>
/* Styles as previously defined */
html, body, #app, .wrapper {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
