<template>
  <div class="container">
    <div class="login-box">
      <div class="login-logo">
        <b>Admin</b> Login
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg"></p>

          <form @submit.prevent="login">
            <div class="input-group mb-3">
              <input type="text" id="pubgmid" class="form-control" placeholder="PUBGM ID" v-model="pubgmid">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" v-model="password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
               <!--  <div class="icheck-primary">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div> -->
              </div>
              <div class="col-4">
                <button type="submit" class="btn btn-primary btn-block">Sign In</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification'; // Import useToast from vue-toastification

export default {
  data() {
    return {
      pubgmid: '',
      password: ''
    };
  },
  methods: {
    login() {
      const toast = useToast(); // Initialize toastification

      // Check if the pubgmid is "admin"
      if (this.pubgmid !== 'admin') {
        toast.error("Only 'admin' users are allowed to log in.", {
          timeout: 5000
        });
        return;
      }

      axios.post('https://pubgmwow.pk/submitForm1.php', {
        action: 'login',
        pubgmid: this.pubgmid,
        password: this.password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.message === "Login successful") {
          // Store token securely
          localStorage.setItem('userToken', response.data.userToken);

          // Redirect to dashboard
          this.$router.replace('/dashboard');
        } else {
          toast.error("Invalid credentials, please try again.", {
            timeout: 5000
          });
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.login-box {
  width: 400px; /* Increased width */
  margin: 0 auto;
}

.login-logo {
  color: rgb(251, 204, 4); /* Adjust text color to contrast with background image */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Optional: add text shadow for better readability */
  text-align: center; /* Center-align the login logo */
  margin-bottom: 20px; /* Add some margin to the bottom */
}

.login-card-body {
  background: rgba(255, 255, 255, 0.8); /* Add a semi-transparent background to make text readable */
  padding: 2rem; /* Increased padding */
  border-radius: 8px;
}

.input-group-text, .form-control {
  background: rgba(255, 255, 255, 0.9);
}
</style>
