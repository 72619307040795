<template>
 <nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
       
      </ul>
      <ul class="navbar-nav ml-auto">
        
        <li class="nav-item">
          <button class="btn btn-warning" @click="logout">Logout</button>
        </li>
      </ul>
  </nav>
</template>


  <script>
  export default {
    name: 'NavbarCom',
    methods: {
      logout() {
        // Clear user authentication token
        localStorage.removeItem('userToken');
        // Redirect user to login page
        this.$router.push('/');
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  