<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="/" class="brand-link">
        <span class="brand-text font-weight-light">PUBGM WOW</span>
      </a>
  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <img src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
          </div>
          <div class="info">
            <a href="#" class="d-block">Admin</a>
          </div>
        </div>
  
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item">             
              <router-link to="/dashboard" class="nav-link active">
                <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
            </li>
            <li class="nav-item">              
              <router-link to="/users" class="nav-link active">
                <i class="nav-icon fas fa-users"></i>
              <p>Users</p>
            </router-link>
            </li>
           <!--  <li class="nav-item">              
              <router-link to="/gamecategories" class="nav-link active">
                <i class="nav-icon fas fa-list-alt"></i>
              <p>Games Categories</p>
            </router-link>
            </li>
            <li class="nav-item">              
              <router-link to="/games" class="nav-link active">
                <i class="nav-icon fas fa-gamepad"></i>
              <p>Games</p>
            </router-link>
            </li> -->
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </template>
  
  <script>
  export default {
    name: 'SidebarCom'
  }
  </script>
  