<template>

        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Dashboard</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div><!-- /.container-fluid -->
        </section>
  
        <!-- Main content -->
        <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-6">
          <div class="small-box bg-info">
            <div class="inner">
              <h3>{{ totalStudents }}</h3>
              <p>Total Students</p>
            </div>
            <!-- ... -->
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <div class="small-box bg-success">
            <div class="inner">
              <h3>{{ shirtWinners }}</h3>
              <p>Shirt Winners</p>
            </div>
            <!-- ... -->
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <div class="small-box bg-warning">
            <div class="inner">
              <h3>{{ capWinners }}</h3>
              <p>Cap Winners</p>
            </div>
            <!-- ... -->
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <div class="small-box bg-danger">
            <div class="inner">
              <h3>{{ darazWinners }}</h3>
              <p>Daraz Gift Cards</p>
            </div>
            <!-- ... -->
          </div>
        </div>
      </div>
    </div>
  </section>

  </template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      totalStudents: 0,
      capWinners: 0,
      shirtWinners: 0,
      darazWinners: 0,
    };
  },
  async created() {
    try {
      const response = await axios.get('https://pubgmwow.pk/submitForm1.php?action=get_winners_count');
      const { total_students, cap_winners, shirt_winners, daraz_winners } = response.data;
      this.totalStudents = total_students;
      this.capWinners = cap_winners;
      this.shirtWinners = shirt_winners;
      this.darazWinners = daraz_winners;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>