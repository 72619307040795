<template>
  <div>
    <h1>Manage Games</h1>
    <Button label="Add Game" @click="openModal()" icon="pi pi-plus" class="mt-2 mb-3" />
    <table class="table">
      <thead>
        <tr>
          <th>Game Name</th>
          <th>Category</th>
          <th>Image Path</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="game in games" :key="game.id">
          <td>{{ game.game_name }}</td>
          <td>{{ categoryTemplate(game) }}</td>
          <td><img :src="'http://localhost:3000/images/' + game.image_path" style="width: 50px; height: 50px;"></td>
          <td>{{ statusTemplate(game) }}</td>
          <td>
            <Button label="Edit" class="p-button-rounded p-button-success" @click="openModal(game)" />
            <Button label="Delete" class="p-button-rounded p-button-danger ml-3" @click="deleteGame(game.id)" />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal for Adding/Editing Games -->
    <Dialog v-model:visible="showModal" :header="isEdit ? 'Edit Game' : 'Add Game'" :modal="true" :closable="false" :style="{ width: '50vw', minHeight: '400px' }">
      <div>
        <input type="text" id="gameName" v-model="formData.game_name" class="form-control mb-4" placeholder="Game Name"/>
        <select v-model="formData.category_id" class="form-control mb-4">
          <option disabled value="">Select a Category</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
        </select>
        <input type="file" @change="handleFileUpload" class="form-control mb-4"/>
        
        <Button label="Save" @click="submitForm" />
        <Button label="Cancel" @click="closeModal" class="p-button-text"/>
      </div>
    </Dialog>
  </div>
</template>
<script>
import axios from 'axios';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
//import DataTable from 'primevue/dialog';
////import Column from 'primevue/button';
//import InputText from 'primevue/dialog';
//import Dropdown from 'primevue/button';
//import Checkbox from 'primevue/dialog';
export default {
  components: {
    Button, Dialog, 
  },
  data() {
    return {
      games: [],
      categories: [],
      formData: {
        game_name: '',
        category_id: '',
        image_path: '',
        status: false
      },
      showModal: false,
      isEdit: false,
      imageFile: null
    };
  },
  methods: {
    fetchGames() {
      axios.get(`${process.env.VUE_APP_API_URL}/games`)
    .then(response => {
      this.games = response.data;
      console.log('Games:', this.games);
    })
    .catch(error => {
      console.error('Failed to fetch games:', error);
    });
    },
    handleFileUpload(event) {
      this.formData.image_path = event.target.files[0];  // Save file for upload
    },
    fetchCategories() {
      axios.get(`${process.env.VUE_APP_API_URL}/gamecategories`).then(response => {
        this.categories = response.data;
      });
    },
    openModal(game = null) {
      this.formData = game ? {...game} : { game_name: '', category_id: '', image_path: '', status: false };
      this.isEdit = !!game;
      this.showModal = true;
    },
    submitForm() {
      const formData = new FormData();
      formData.append('game_name', this.formData.game_name);
      formData.append('category_id', this.formData.category_id);
      formData.append('image', this.formData.image_path);
      formData.append('status', true);

      axios.post(`${process.env.VUE_APP_API_URL}/games`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.fetchGames();
        this.closeModal();
      }).catch(error => {
        console.error('Failed to upload game:', error);
      });
    },
    closeModal() {
      this.showModal = false;
    },
    deleteGame(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}/games/${id}`).then(() => {
        this.fetchGames();
      });
    },
    categoryTemplate(rowData) {
      const category = this.categories.find(c => c.id === rowData.category_id);
      return category ? category.category : 'No category';
    },
    imageTemplate(rowData) {
      return `<img src="/images/${rowData.image_path}" style="width: 100px; height: auto;" />`;
    },
    statusTemplate(rowData) {
      return rowData.status ? 'Active' : 'Inactive';
    },
    actionTemplate(rowData) {
      return {
        template: `
          <div>
            <span>`+rowData+`</span>
            <Button label="Edit" class="p-button-rounded p-button-success" @click="openModal(rowData)" />
            <Button label="Delete" class="p-button-rounded p-button-danger" @click="deleteGame(rowData.id)" />
          </div>
        `
      };
    }
  },
  mounted() {
    this.fetchGames();
    this.fetchCategories();
  }
}
</script>

<style scoped>
.dialog-content {
  padding: 20px;
}
</style>
